import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { GlobalTranslateService } from '../../../services/global-translate.service';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css']
})
export class DatatableComponent implements OnInit, AfterViewInit, OnDestroy {
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [];
  dataType: string = '';
  pageSize: number = 25;
  private routeSubscription: Subscription | undefined;
  private sortSubscription: Subscription | undefined;
  private isFetchingData: boolean = false; // Add a flag to track API call status

  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;

  constructor(
    private route: ActivatedRoute,
    private globalService: GlobalTranslateService,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    console.log('ngOnInit');
    this.routeSubscription = this.route.url.subscribe(urlSegments => {
      console.log('Route subscription');
      this.dataType = urlSegments[urlSegments.length - 1].path;
      this.fetchData(this.dataType, 0, this.pageSize, '');
    });
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit');
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    if (!this.sortSubscription && this.sort) {
      console.log('Subscribing to sort events');
      this.sortSubscription = this.sort.sortChange.subscribe((sort: Sort) => {
        console.log('Sort event:', sort);
        this.paginator.pageIndex = 0;
        this.fetchData(this.dataType, this.paginator.pageIndex, this.paginator.pageSize, this.dataSource.filter);
      });
    }
  }

  ngOnDestroy() {
    console.log('ngOnDestroy');
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.sortSubscription) {
      this.sortSubscription.unsubscribe();
    }
  }

  applyFilter(event: Event) {
    console.log('Applying filter');
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.paginator.pageIndex = 0;
    this.fetchData(this.dataType, this.paginator.pageIndex, this.paginator.pageSize, filterValue);
  }

  fetchData(endpoint: string, page: number, limit: number, filterValue: string) {
    if (this.isFetchingData) {
      console.log('Already fetching data, skipping');
      return;
    }

    this.isFetchingData = true; // Set the flag to true before making the API call
    console.log('Fetching data', { endpoint, page, limit, filterValue });
    const params: any = { page, limit };

    if (filterValue) {
      params.filter = filterValue;
    }

    if (this.sort && this.sort.active && this.sort.direction) {
      params.sort = `${this.sort.active},${this.sort.direction}`;
    }

    this.apiService.getData(endpoint, params).subscribe(response => {
      console.log('API response', response);
      if (response.status && response.data && response.data.docs.length) {
        this.displayedColumns = Object.keys(response.data.docs[0]);
                                    // .filter(column => column !== '_id');
        this.dataSource.data = response.data.docs;
        setTimeout(() => {
          this.paginator.pageIndex = response.data.page - 1;
          this.paginator.length = response.data.totalDocs;
        });
      } else {
        console.error('No data received from the API.');
        this.dataSource.data = [];
        this.paginator.length = 0;
      }
      this.isFetchingData = false; // Reset the flag after the API call is complete
    }, () => {
      this.isFetchingData = false; // Reset the flag in case of error
    });
  }

  onPaginateChange(event: PageEvent) {
    console.log('onPaginateChange', event);
    if (!this.isFetchingData) {
      this.fetchData(this.dataType, event.pageIndex + 1, event.pageSize, this.dataSource.filter);
    } else {
      console.log('Skip fetch due to ongoing fetch');
    }
  }
}
