import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.css'
})
export class ButtonComponent {
  @Input() type:string='submit';
  @Input() class:string='';
  @Input() id:string='';
  @Input() value:string='';
  @Input() color:string='primary';

}