import { Component, Input, Output, EventEmitter , OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms'; 
import { MatFormFieldModule, MatFormFieldAppearance } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.css'
})
export class TextInputComponent implements OnInit,OnChanges {
  @Input() label: string = '';
  @Input() pattern:string = '';
  @Input() maxlength:string = '100';
  @Input() minlength:string = '0';
  @Input() type: string = 'text';
  @Input() name: string = '';
  @Input() id: string = '';
  @Input() class: string = '';
  @Input() value: string = '';
  @Input() isInvalid: boolean = false;
  @Input() placeholder: string = '';
  @Input() autocomplete: string='off';
  @Input() isRequired: boolean=true;
  @Output() valueChange = new EventEmitter<string>();

  appearance: MatFormFieldAppearance = 'fill';
  control: FormControl = new FormControl('');

  ngOnInit() {
    this.control = new FormControl(this.value, [
      Validators.pattern(this.pattern),
      Validators.maxLength(parseInt(this.maxlength, 10))
    ]);
  }
  onInputChange(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.valueChange.emit(inputValue);
    this.control.setValue(inputValue);
    this.updateInvalidState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes",changes, changes['isInvalid'],changes['value']);
    if (changes['value']) {
      this.control.setValue(this.value);
    }

    if (changes['isInvalid']) {
      console.log("changes-invalid");
      this.updateInvalidState();
    }
  }

  onFocus(): void {
    this.isInvalid = false;
  }

  onBlur(): void {
    this.updateInvalidState();
  }
  
  private updateInvalidState(): void {
    this.isInvalid = this.control.invalid;// && (this.control.dirty || this.control.touched);
  }
}
