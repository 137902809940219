<mat-form-field appearence="appearence" [class.invalid]="isInvalid">
	<mat-label [attr.for]="id">{{ label | translate }}</mat-label>
	<input matInput #input 
		[attr.pattern]="pattern"
		[attr.maxlength]="maxlength"
		[attr.minlength]="minlength"
		[type]="type" 
		[name]="name" 
		[id]="id" 
		[class]="class"
		[value]="value"
		[placeholder]="placeholder | translate" 
		(input)="onInputChange($event)"
		[autocomplete]="autocomplete"
		[formControl]="control"
		[attr.aria-describedby]="id + '-error'"
		(focus)="onFocus()"
		(blur)="onBlur()"
		[attr.required]="isRequired"
	/>
	<mat-hint align="start">
		<!-- {{input.value.length}}/{{ maxlength }} -->
		<span [class.invalid]="isInvalid">
			{{ 'Invalid' | translate}} {{ label | translate }}.
		</span>
	</mat-hint>
</mat-form-field>