<app-header />
<div class="container">
  <div class="content">
    <div class="home-container">
    <h1>Welcome to Our Website</h1>
    <button mat-raised-button color="primary" [routerLink]="['/auth']" [queryParams]="{ tab: 'login' }">Login</button>
    <button mat-raised-button color="accent" [routerLink]="['/auth']" [queryParams]="{ tab: 'register' }">Register</button>
  </div>
  </div>
</div>
<app-footer />
