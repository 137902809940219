import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm  } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';

import { RegistrationError } from '../../../shared/responses';
import { GlobalTranslateService } from '../../../services/global-translate.service';
import { AuthService } from '../../../services/auth.service';
import {  User, defaultUser } from '../../../interface/user';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.css'
})
export class RegisterComponent {
  registerForm: FormGroup;
  invalidStates: { [key: string]: boolean } = {};
  constructor(
    private fb : FormBuilder, 
    public globalService : GlobalTranslateService,
    private authService : AuthService
  ){
    this.registerForm = this.fb.group({
      first_name: ['', [Validators.required, Validators.maxLength(100)]],
      last_name: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
      phone: ['',[Validators.required]]
    }, {
      // validators : emailOrMobileValidator
    });
  }

  onSubmit(): void {
    if (this.registerForm.invalid) {
      this.updateInvalidStates();
      this.globalService.error("Register","Some input is missing");
      return;
    }
    this.authService.register(this.registerForm.value).subscribe(
      response => {
         // this.resetForm(this.registerForm);
        this.globalService.success("Register","Form submitted successfully");
      },
      error => {
        this.globalService.error("Register",error?.error?.message,error?.error?.key);
      }
    );
  }

  private updateInvalidStates() {
    Object.keys(this.registerForm.controls).forEach(key => {
      this.registerForm.controls[key].markAsTouched();
      this.invalidStates[key] = this.registerForm.controls[key].invalid;
    });
  }

  resetForm(registerForm: NgForm) {
    registerForm.resetForm();
  }
}
