import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm  } from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';

import { RegistrationError } from '../../../shared/responses';
import { GlobalTranslateService } from '../../../services/global-translate.service';
import { AuthService } from '../../../services/auth.service';
import {  User, defaultUser } from '../../../interface/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  loginForm: FormGroup;
  invalidStates: { [key: string]: boolean } = {};

  constructor(
    private fb : FormBuilder, 
    public globalService : GlobalTranslateService,
    private authService : AuthService
  ){
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
    }, {
      // validators : emailOrMobileValidator
    });
  }
  
  onSubmit(): void {
    if (this.loginForm.invalid) {
      this.updateInvalidStates();
      this.globalService.error("Register","Some input is missing");
      return;
    }
    this.authService.login(this.loginForm.value).subscribe(
      response => {
        this.globalService.success("Register","Login successfully");
      },
      error => {
        this.globalService.error("Register",error?.error?.message,error?.error?.key);
      }
    );
  }

  private updateInvalidStates() {
    Object.keys(this.loginForm.controls).forEach(key => {
      this.loginForm.controls[key].markAsTouched();
      this.invalidStates[key] = this.loginForm.controls[key].invalid;
    });
  }

  resetForm(loginForm: NgForm) {
    loginForm.resetForm();
  }

}