<div class="mat-elevation-z8">
  <div class="filter-container">
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="{{'Search' | translate}}">
    </mat-form-field>
  </div>
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- Dynamic Column Definitions -->
    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ column | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element[column] }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator 
    #paginator
    [length]="paginator.length"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 20, 25, 100, 250]"
    (page)="onPaginateChange($event)"
    showFirstLastButtons
    aria-label="Select page"
    >
  ></mat-paginator>
</div>