<mat-card class="card">
  <mat-card-header>
    <div mat-card-avatar class="card-avatar"></div>
    <mat-card-title>{{ 'login' | translate }}</mat-card-title>
    <mat-card-subtitle>{{ 'login to receive benefits' | translate }}</mat-card-subtitle>
  </mat-card-header>
  <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="{{ 'login img' | translate }}"> -->
 	<form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <mat-card-content>
	<app-text-input 
		label="Email" 
		type="email" 
		name="email" 
		id="login-email" 
		class="input login-input" 
		maxlength="255" 
		minlength="3"
    pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
		[value]="loginForm.get('email')?.value" 
		(valueChange)="loginForm.patchValue({email: $event})"
		[isInvalid]="invalidStates['email']"
		placeholder="Please enter email" 
		autocomplete="off"
		required="true"
	/>
	<app-text-input 
		label="Phone" 
		type="text" 
		name="phone" 
		id="login-phone" 
		class="input login-input" 
		maxlength="15" 
		minlength="10"
		pattern="^\+?\d{10,15}$"
		[value]="loginForm.get('phone')?.value" 
		(valueChange)="loginForm.patchValue({phone: $event})"
		[isInvalid]="invalidStates['phone']"
		placeholder="Please enter phone" 
		autocomplete="off"
		required="true"
	/>

  </mat-card-content>
  <mat-card-actions align="end">
    <app-button 
		type="submit" 
		value="login" 
		id="login-submit" 
		class="login-submit" 
		color="primary"
	/>
    <app-button 
		type="reset" 
		value="Reset" 
		id="login-reset" 
		class="login-reset" 
		color="warn"
	/>
  </mat-card-actions>
</form>
</mat-card>


