import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalTranslateService {
  constructor(
    private translate: TranslateService,
    private toastr: ToastrService
  ) {
    const lang = this.translate.currentLang || this.translate.getDefaultLang();
    this.translate.use(lang);
  }

  trans(message: string, params: any = {}): string {
    return this.translate.instant(message, params);
  }

  success(title: string, message: string, params: any = {}): void {
    const translatedMessage: string = this.trans(message, params);
    const translatedTitle: string = this.trans(title);
    this.toastr.success(translatedMessage, translatedTitle);
  }

  warning(title: string, message: string, params: any = {}): void {
    const translatedMessage: string = this.trans(message, params);
    const translatedTitle: string = this.trans(title);
    this.toastr.warning(translatedMessage, translatedTitle);
  }

  info(title: string, message: string, params: any = {}): void {
    const translatedMessage: string = this.trans(message, params);
    const translatedTitle: string = this.trans(title);
    this.toastr.info(translatedMessage, translatedTitle);
  }

  error(title: string, message: string, params: any = {}): void {
    const translatedMessage: string = this.trans(message, params);
    const translatedTitle: string = this.trans(title);
    this.toastr.error(translatedMessage, translatedTitle);
  }
}
