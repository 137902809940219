import { Component } from '@angular/core';
import { DatatableComponent } from '../../custom/datatable/datatable.component';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrl: './users.component.css'
})
export class UsersComponent {

}
