<!-- src/app/auth/auth.component.html -->
<div class="container">
  <div class="tab-container">
    <mat-tab-group [(selectedIndex)]="selectedTabIndex" class="tab-group">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">login</mat-icon>
          {{ 'Login' | translate }}
        </ng-template>
        <app-login></app-login>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">subscriptions</mat-icon>
          {{ 'Register' | translate }}
        </ng-template>
        <app-register></app-register>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
