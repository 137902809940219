import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ADMIN_URL } from '../shared/url';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) {}

  getData(endpoint: string, params: any): Observable<any> {
    let queryParams = new HttpParams();

    if (params) {
      // Add each parameter to the HttpParams object
      Object.keys(params).forEach(key => {
        queryParams = queryParams.append(key, params[key]);
      });
    }

    // Construct the URL with query parameters
    const url = `${ADMIN_URL}/${endpoint}`;

    // Pass HttpParams as options to the get method
    return this.http.get(url, { params: queryParams });
  }
}
