import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {
 constructor(
    public translate : TranslateService,
    public toastr : ToastrService
  ) {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }
  
  switchLang(lang: string) {
    this.translate.use(lang);
  }

  toast(method: string, title: string, msg: string) {
    switch (method) {
      case 'error':
        this.toastr.error(msg, title);
        break;
      case 'warning':
        this.toastr.warning(msg, title);
        break;
      case 'info':
        this.toastr.info(msg, title);
        break;
      case 'success':
      default:
        this.toastr.success(msg, title);
    }
  }

}
