// System modules or components

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule} from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Components

import { LoginComponent } from './components/auth/login/login.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { LeftsidebarComponent } from './components/layouts/leftsidebar/leftsidebar.component';
import { HomeComponent } from './components/layouts/home/home.component';
// import { PhoneComponent } from './components/custom/phone/phone.component';
import { TextInputComponent } from './components/custom/text-input/text-input.component';
import { ButtonComponent } from './components/custom/button/button.component';

// Directives or Validators
import { NumericOnlyDirective } from './directices/numeric-only.directive';
import { AuthComponent } from './components/layouts/auth/auth.component';
import { DatatableComponent } from './components/custom/datatable/datatable.component';
import { AdminComponent } from './components/layouts/admin/admin.component';
import { UsersComponent } from './components/admin/users/users.component';
import { DashboardComponent } from './components/admin/dashboard/dashboard.component';
import { BannersComponent } from './components/admin/banners/banners.component';
import { AdminHeaderComponent } from './components/layouts/admin-header/admin-header.component';

// Localization files
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HeaderComponent,
    FooterComponent,
    LeftsidebarComponent,
    HomeComponent,
    // PhoneComponent,
    TextInputComponent,
    ButtonComponent,
    NumericOnlyDirective,
    AuthComponent,
    DatatableComponent,
    AdminComponent,
    UsersComponent,
    DashboardComponent,
    BannersComponent,
    AdminHeaderComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTableModule,
    MatTabsModule,
    MatSortModule,
    NgxMatIntlTelInputComponent,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      enableHtml: true,
      closeButton: true,
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
